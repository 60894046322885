import React from 'react'
import type { CSSObject } from '@emotion/react'
import renderGlobalStyles from './Global'
import Seo from './Seo'

function Layout({
  background,
  backgroundColor,
  backgroundImage,
  children,
  className,
  mainStyles,
  ...rest
}: React.PropsWithChildren<
  Parameters<typeof renderGlobalStyles>[0] & React.HTMLAttributes<HTMLElement>
>) {
  return (
    <>
      {renderGlobalStyles({
        background,
        backgroundColor,
        backgroundImage,
        mainStyles,
      })}
      <Seo />
      <main
        style={{ boxSizing: 'border-box', overflowX: 'hidden' }}
        className={className}
        {...rest}
      >
        {children}
      </main>
    </>
  )
}

export default Layout
