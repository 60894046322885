import type { CSSObject } from '@emotion/react'
import { Global } from '@emotion/react'

export default function renderGlobalStyles({
  background,
  backgroundColor,
  backgroundImage,
  mainStyles,
}: Pick<CSSObject, 'background' | 'backgroundColor' | 'backgroundImage'> & {
  mainStyles?: CSSObject
} = {}) {
  return (
    <Global
      styles={{
        '*': {
          boxSizing: `inherit`,
          '&:before': {
            boxSizing: `inherit`,
          },
          '&:after': {
            boxSizing: `inherit`,
          },
        },
        html: {
          fontSize: `18px`,
          WebkitTextSizeAdjust: `100%`,
        },
        body: {
          position: 'fixed',
          fontFamily: 'Open Sans',
          width: '100%',
        },
        img: {
          borderStyle: `none`,
        },
        p: {
          fontSize: [1, 2],
          letterSpacing: `-0.003em`,
          lineHeight: `body`,
          '--baseline-multiplier': 0.179,
          '--x-height-multiplier': 0.35,
          color: `text`,
        },
        pre: {
          fontFamily: `Consolas`,
          fontSize: `1em`,
        },
        blockquote: {
          marginLeft: 0,
          p: {
            fontSize: [2, 3],
            fontWeight: `medium`,
            color: `blue.400`,
          },
        },
        main: {
          background,
          backgroundColor,
          backgroundImage,
          color: 'white',
          height: '100%',
          minHeight: '100vh',
          ...mainStyles,
        },
        h1: {
          // fontSize: ,
          marginTop: 2,
          marginBottom: 3,
          textShadow: `rgba(255, 255, 255, 0.15) 0px 5px 35px`,
          letterSpacing: `wide`,
          // color: `heading`,
        },
        h2: {
          marginTop: 2,
          marginBottom: 2,
          // fontSize: [4, 5, 6],
          // color: `heading`,
        },
        h3: {
          marginBottom: 3,
          // fontSize: [3, 4, 5],
          // color: `heading`,
        },
        h4: {
          // fontSize: [2, 3, 4],
        },
        h5: {
          // fontSize: [1, 2, 3],
        },
        h6: {
          // fontSize: 1,
          mb: 2,
        },
        '[hidden]': {
          display: `none`,
        },
        '::selection': {
          backgroundColor: `linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%)`,
          color: '#473B7B',
        },
      }}
    />
  )
}
